export const routes = {
  HOME: '/',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  PROGRAMS: 'programs',
  COACHES: 'coach',
  COACH: 'coach/:id',
  OUTLIERS: 'outliers',
  BLA_FILTER: 'bla-filter',
  PROGRAM: 'programs/:id',
  UPDATE_PROGRAM: 'update-program/:id',
  CREATE_PROGRAM: 'create-program',
  DASHBOARD: 'dashboard',
  DASHBOARD_COMPARE: 'dashboard/compare/:id1/:id2',
  MEMBERS: 'members',
  MEMBER: 'members/:id',
  HEALTH_PASSPORT_HEALTH_CONDITIONS_HISTORY: 'members/:id/health-passport/health-condition-history',
  HEALTH_PASSPORT_MEDICATIONS_HISTORY: 'members/:id/health-passport/medications-history',
  HEALTH_PASSPORT_PRESCRIPTIONS_HISTORY: 'members/:id/health-passport/prescriptions-history',
  HEALTH_PASSPORT_PRESCRIPTION_DETAILS:
    'members/:id/health-passport/prescriptions-history/:prescriptionId',
  HEALTH_PASSPORT_EYE_EXAM_HISTORY: 'members/:id/health-passport/eye-exam-history',
  HEALTH_PASSPORT_FOOT_EXAM_HISTORY: 'members/:id/health-passport/foot-exam-history',
  HEALTH_PASSPORT_PROVIDER_HISTORY: 'members/:id/health-passport/provider-history',
  HEALTH_PASSPORT_PHARMACY_HISTORY: 'members/:id/health-passport/pharmacy-history',
  HEALTH_PASSPORT_LAB_HISTORY: 'members/:id/health-passport/lab-history',
  HEALTH_PASSPORT_PROCEDURES_HISTORY: 'members/:id/health-passport/procedures-history',
  HEALTH_DIARY_WEIGHT: 'members/:id/health-diary/weight',
  HEALTH_DIARY_HEIGHT: 'members/:id/health-diary/height',
  HEALTH_DIARY_BMI: 'members/:id/health-diary/bmi',
  HEALTH_DIARY_BLOOD_PRESSURE: 'members/:id/health-diary/blood-pressure',
  HEALTH_DIARY_PSA: 'members/:id/health-diary/psa-level',
  HEALTH_DIARY_EXERCISE: 'members/:id/health-diary/exercise',
  HEALTH_DIARY_MEDICATION: 'members/:id/health-diary/medication',
  HEALTH_DIARY_CHOLESTEROL: 'members/:id/health-diary/cholesterol',
  HEALTH_DIARY_PREGNANCY: 'members/:id/health-diary/pregnancy',
  HEALTH_DIARY_WAIST_CIRCUMFERENCE: 'members/:id/health-diary/waist-circumference',
  HEALTH_DIARY_BLOOD_SUGAR: 'members/:id/health-diary/blood-sugar',
  BLA_HISTORY_WITH_DATE: 'members/:id/bla/:date',
  REPORT: 'report',
  SUBSCRIPTIONS: 'subscriptions',
  CONFIGURATION: 'configuration',
  ROLES_PRIVILEGES: 'roles-privileges',
  ADD_ROLE: 'roles-privileges/add-role',
  BLOGS: 'blogs',
  ADD_BLOG: 'blogs/add',
  EDIT_BLOG: 'blogs/:id',
  WEBINARS: 'webinars',
  ADD_WEBINAR: 'webinars/add',
  SINGLE_WEBINAR: 'webinars/:id',
  ADMIN: 'admins',
  PUBLICATION: 'publication',
  PUBLICATION_ID: 'publication/:id',
  ADD_PUBLICATION: 'publication/add',
  NEWS: 'news',
  NEWS_ID: 'news/:id',
  ADD_NEWS: 'news/add',
  IMPACT_REPORTS: 'impact-reports',
  IMPACT_REPORTS_ID: 'impact-reports/:id',
  ADD_IMPACT_REPORT: 'impact-reports/add',
  APP_RELEASE: 'app-release',
  CAREER: 'careers',
  ADD_CAREER: 'careers/add',
  HUB_VISIT: 'hub-visit',
};
