import { PropsWithChildren, Suspense, useEffect, useLayoutEffect } from 'react';
import postHog from 'posthog-js';
import { PostHogProvider as PHProvider } from 'posthog-js/react';

import { POSTHOG_API_KEY, POSTHOG_HOST } from 'api/index';
import PostHogPageView from './PostHogPageView';
import { useAppSelector } from '../../redux/hooks/useAppSelector';

if (typeof window !== 'undefined') {
  const apiKey = POSTHOG_API_KEY;
  const apiHost = POSTHOG_HOST;

  if (apiKey && apiHost) {
    postHog.init(apiKey, {
      api_host: apiHost,
      capture_pageview: false,
    });
  }
}

const PostHogProvider = ({ children }: PropsWithChildren) => {
  const user = useAppSelector((state) => state.user.currentUser);

  useLayoutEffect(() => {
    if (user) {
      postHog.identify(user?.id, { email: user?.email, phone: user?.phoneNumber });
    }
  }, [user]);

  return (
    <PHProvider client={postHog}>
      {children}
      <Suspense fallback={'...'}>
        <PostHogPageView />
      </Suspense>
    </PHProvider>
  );
};

export default PostHogProvider;
