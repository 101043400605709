import { Close } from '@mui/icons-material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { animated, useSpring } from '@react-spring/web';
import * as React from 'react';
import useBreakpointValue from 'utils/useBreakpointValue/useBreakpointValue';
import styles from './modal.module.css';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { children, in: open, onClick, onEnter, onExited, ownerState, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

export interface IModalProps {
  title?: string;
  isOpen?: boolean;
  onClose: () => void;
  children: any;
  size?: 'lg' | 'sm';
  hideClose?: boolean;
  disableBackdropClick?: boolean;
}

export default function ModalComponent({
  isOpen,
  onClose,
  children,
  size = 'sm',
  title,
  hideClose = false,
  disableBackdropClick = false,
}: IModalProps) {
  const sm = useBreakpointValue({
    base: '95%',
    md: '50%',
    lg: '40%',
    xl: '40%',
    xxl: '30%',
  });
  const lg = useBreakpointValue({
    base: '95%',
    md: '70%',
    lg: '70%',
    xl: '60%',
    xxl: '40%',
  });
  const maxHeight = useBreakpointValue({
    base: '90vh',
    md: '80vh',
  });
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: size === 'sm' ? sm : size === 'lg' ? lg : '70%',
    bgcolor: 'background.paper',
    border: '1px solid #DEDEDE',
    boxShadow: 24,
    p: 0,
    borderRadius: 2,
    maxHeight,
    overflowY: 'auto',
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={isOpen}
      onClose={(e, reason) => {
        if (reason === 'backdropClick' && disableBackdropClick) {
          return;
        }
        onClose?.();
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style} className={`${styles.scrollbar_thinner}`}>
          <div className="flex justify-between border-b-[0.5px] z-50 p-4 border-gray-100 mb-4 sticky top-0 bg-white">
            <p className="text-lg font-semibold">{title}</p>
            {!hideClose && (
              <div
                onClick={onClose}
                className="bg-lightBlueShade hover:bg-blueShade cursor-pointer hover:text-white text-blueShade rounded-full w-max h-max p-2 flex items-center"
              >
                <Close className="!h-4 !w-4" />
              </div>
            )}
          </div>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}
