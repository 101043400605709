/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImpactReports } from '../models/ImpactReports';
import type { ImpactReportsWithRelations } from '../models/ImpactReportsWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ImpactReportsControllerService {
  /**
   * @returns any ImpactReports model count
   * @throws ApiError
   */
  public static impactReportsControllerStatistics(): CancelablePromise<{
    totalImpactReportsCount?: number;
    publishedImpactReportsCount?: number;
    unpublishedImpactReportsCount?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/impact-reports/statistics',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static impactReportsControllerUpdateById({
    id,
    formData,
  }: {
    id: string;
    formData?: {
      title?: string;
      pdfUrl?: string;
      status?: string;
      description?: string;
      publicationDate?: string;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/impact-reports/{id}',
      path: {
        id: id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns ImpactReportsWithRelations ImpactReports model instance
   * @throws ApiError
   */
  public static impactReportsControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<ImpactReportsWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/impact-reports/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static impactReportsControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/impact-reports/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns ImpactReports ImpactReports model instance
   * @throws ApiError
   */
  public static impactReportsControllerCreate({
    formData,
  }: {
    formData?: {
      title?: string;
      pdfUrl?: string;
      status?: string;
      description?: string;
      publicationDate?: string;
    };
  }): CancelablePromise<ImpactReports> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/impact-reports',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns any Array of ImpactReports model instances
   * @throws ApiError
   */
  public static impactReportsControllerFind({
    search,
    status,
    filter,
    page,
    startDate,
    endDate,
    sortOrder,
  }: {
    search?: string;
    status?: string;
    filter?: any;
    page?: number;
    startDate?: string;
    endDate?: string;
    sortOrder?: string;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<ImpactReportsWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/impact-reports',
      query: {
        search: search,
        status: status,
        filter: filter,
        page: page,
        startDate: startDate,
        endDate: endDate,
        sortOrder: sortOrder,
      },
    });
  }
}
