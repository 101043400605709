/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { News } from '../models/News';
import type { NewsWithRelations } from '../models/NewsWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NewsControllerService {
  /**
   * @returns any News model count
   * @throws ApiError
   */
  public static newsControllerStatistics(): CancelablePromise<{
    totalNewsCount?: number;
    publishedNewsCount?: number;
    unpublishedNewsCount?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/news/statistics',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static newsControllerUpdateById({
    id,
    formData,
  }: {
    id: string;
    formData?: {
      title?: string;
      description?: string;
      content?: string;
      status?: string;
      publicationDate?: string;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/news/{id}',
      path: {
        id: id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns NewsWithRelations News model instance
   * @throws ApiError
   */
  public static newsControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<NewsWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/news/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static newsControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/news/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns News News model instance
   * @throws ApiError
   */
  public static newsControllerCreate({
    formData,
  }: {
    formData?: {
      title?: string;
      description?: string;
      content?: string;
      status?: string;
      publicationDate?: string;
    };
  }): CancelablePromise<News> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/news',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns any Array of News model instances
   * @throws ApiError
   */
  public static newsControllerFind({
    search,
    status,
    startDate,
    endDate,
    filter,
    page,
    sortOrder,
  }: {
    search?: string;
    status?: string;
    startDate?: string;
    endDate?: string;
    filter?: any;
    page?: number;
    sortOrder?: string;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<NewsWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/news',
      query: {
        search: search,
        status: status,
        startDate: startDate,
        endDate: endDate,
        filter: filter,
        page: page,
        sortOrder: sortOrder,
      },
    });
  }
}
